<template>
    <div>
        <modal :name="modal_name" transition="nice-modal-fade" class="final-modal">
            <div class="v-modal-content">
                <div class="v-modal-header align-items-center">
                    <span class="v-modal-dialog-title v-modal-title">Edit Combo</span>
                    <span class="pull-right" style="cursor:pointer;" @click="cancel()">
                        <i class="icon icon-close" style="font-size: 22px!important; color: #5d5b5b;"></i>
                    </span>
                </div>
                <div class="v-modal-body pr-0">
                    <div class="v-modal-layout pr-3 comboModal" style="padding-right: 18px;max-height: 430px !important; overflow-x: hidden !important;overflow-y: scroll !important">
                        <div class="section">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">General Section</span>
                            </div>
                            <div class="row first-middle-input">
                                <div class="col-md-3">
                                    <span class="ml-5" style="text-align:center;">
                                        <img v-if="create.image_url" :src="create.image_url" style="width:90px!important;height:90px!important;"/>
                                        <img v-else :src="imgDataUrl" style="width: 90px!important;height:90px!important;"/>
                                    </span>
							        <upload-picture @uploadImage="uploadImage"></upload-picture>
                                </div>
                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-lg-12 mt-2">
                                            <span style="font-weight: 600;">Status:<sup>★</sup></span>
                                            <el-switch v-model="create.status" active-text="Yes" inactive-text="No" class="pl-4"></el-switch>
                                        </div>
                                        <div class="col-lg-12 mt-5">
                                            <div class="form-group contactGroup">
                                                <input class="inputContact" name="combo_name" v-validate="'required'" :maxlength="combonamelength" required autocomplete="off" v-model="create.name" type="text"/>
                                                <span class="highlightContact"></span>
                                                <span class="barContact"></span>
                                                <label class="labelContact">Combo Name<sup>★</sup></label>
                                                <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('combo_name')" style="display: inline !important">{{errors.first('combo_name')}}</span>
                                                <p class="pull-right text-right text-grey font-600 fs-12 pt-1">{{combonamelength - create.name.length}} / {{combonamelength}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Category<sup>★</sup></label>
                                    <multiselect
                                        v-model="create.category_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        placeholder="Select Category"
                                        :options="sectionCategories"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @close="updateSubcategory()"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-md-6">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Sub Category<sup>★</sup></label>
                                    <multiselect
                                        v-model="create.sub_category_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        disabled
                                        placeholder="Select Category"
                                        :options="[]"
                                        :multiple="false"
                                        :searchable="true"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                            <div class="row mt-5">
                                <div class="col-md-6" v-if="available_productType">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product Type<sup>★</sup></label>
                                    <multiselect
                                        v-model="create.product_type_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        placeholder="Select Product Type"
                                        :options="allProductTypes"
                                        :multiple="false"
                                        :searchable="true"
                                        :loading="loadingProductType"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        name="productType"
                                        v-validate="'required'"
                                        @search-change="loadProductTypeSearch"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListProductType" />
                                    </template>
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('productType')" style="display:inline;">Please Select Product Type</span>
                                </div>
                                <div class="col-md-6" v-if="!available_productType">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product Type<sup>★</sup></label>
                                    <multiselect
                                        v-model="create.product_type_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        placeholder="Select Product Type"
                                        :options="allProductTypes"
                                        :multiple="false"
                                        :searchable="true"
                                        :loading="loadingProductType"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        @search-change="loadProductTypeSearch"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListProductType" />
                                    </template>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="section mt-5" v-for="(prod,index) in create.combo_items" :key="index">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Product {{index+1}}</span>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Product<sup>★</sup></label>
                                    <multiselect
                                        v-model="prod.product_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        placeholder="Select Product"
                                        :options="allproducts"
                                        :multiple="false"
                                        :searchable="true"
                                        :loading="isLoadingproduct"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :name="'product'+index"
                                        v-validate="'required'"
                                        @search-change="loadProductSearch"
                                        @close="checkVariant(prod.product_id,index)"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    <template slot="afterList">
                                        <div v-observe-visibility="reachedEndOfListProduct" />
                                    </template>
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('product'+index)" style="display:inline;">Please Select Product</span>
                                </div>
                                <div class="col-md-6" v-if="prod.variant">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Variant<sup>★</sup></label>
                                    <multiselect
                                        v-model="prod.variant_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="variant_name"
                                        placeholder="Select Variant"
                                        :options="allVariants"
                                        :multiple="false"
                                        :searchable="true"
                                        :loading="isLoading"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :name="'variant'+index"
                                        v-validate="'required'"
                                        @open="getVariant(prod.product_id)"
                                        @close="selectVariant(prod.variant_id,index,prod)"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.variant_name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('variant'+index)" style="display:inline;">Please Select Variant</span>
                                </div>
                                <div class="col-md-6" :class="{'topmargin':!prod.variant}">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" :disabled="prod.product_id == ''" @input="checkQuantity(prod.quantity,prod.product_id,index,prod.variant_id)" onkeypress='return event.charCode >= 48 && event.charCode <= 57' :name="'qty'+index" v-validate="'required'"  required autocomplete="off" v-model.number="prod.quantity" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Quantity<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('qty'+index)" style="display: inline !important">Quantity is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input disabled class="inputContact" v-on:keypress="isNumber($event)" :name="'price'+index" v-validate="'required'"  required autocomplete="off" v-model.number="prod.total_price" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Price<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('price'+index)" style="display: inline !important">Price is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" :disabled="prod.quantity == 0" @input="checkDiscount(prod,index)" v-on:keypress="isNumber($event)" :name="'discount'+index" v-validate="'required'"  required autocomplete="off" v-model.number="prod.discount" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Discount<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('discount'+index)" style="display: inline !important">Discount is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" disabled v-on:keypress="isNumber($event)" :name="'eff_price'+index" v-validate="'required'"  required autocomplete="off" v-model.number="prod.price_after_discount" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Effective Price After Discount<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('eff_price'+index)" style="display: inline !important">Effective Price After Discount is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="index!=0">
                                    <button :class="{'pull-right':!prod.variant}" class="btn btn-danger btn-smm text-white mt-5" @click="create.combo_items.splice(index, 1);handleRemoveProdcut(prod)">
                                        - Remove Product
                                    </button>
                                </div>
                                <div class="col-md-6" v-if="index+1==create.combo_items.length">
                                    <button class="btn btn-success btn-smm text-white mt-5" @click="create.combo_items.push(JSON.parse(JSON.stringify(addproduct)))">
                                        + Add Product
                                    </button>
                                </div>
                            </div> 
                        </div>
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Discount Section</span>
                            </div>
                            <div class="row mt-5" v-for="(discount_break,index) in create.discount_breakdown" :key="index">
                                <div class="col-md-6">
                                    <label class="text-secondary" style="margin-bottom:0px;font-size: 14px;">Category<sup>★</sup></label>
                                    <multiselect
                                        v-model="discount_break.category_id"
                                        class="digi-multiselect"
                                        id="ajax"
                                        label="name"
                                        placeholder="Select Category"
                                        :options="discountCategories"
                                        :multiple="false"
                                        :searchable="true"
                                        :loading="isLoading"
                                        :internal-search="false"
                                        :clear-on-select="false"
                                        :close-on-select="true"
                                        :options-limit="40"
                                        :max-height="200"
                                        :show-no-results="true"
                                        :hide-selected="false"
                                        :name="'category'+index"
                                        v-validate="'required'"
                                        @open="discountPush()"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="custom__tag">
                                            <span>{{ option.name }}</span>
                                            <span class="custom__remove" @click="remove(option)">❌</span>
                                        </span>
                                    </template>
                                    </multiselect>
                                    <span class="invalid-feedback-form" v-show="errors.has('category'+index)" style="display:inline;">Please Select Category</span>
                                </div>
                                <div class="col-md-6 d-flex" style="margin-top:-7px;">
                                    <div class="input-group" style="display: -webkit-inline-box;" v-if="discount_break.category_id != ''">
                                        <div class="form-group contactGroup labelContactnew" style="width:100%;">
                                            <input class="inputContact" @input="discountCategory(discount_break,index)" v-on:keypress="isNumber($event)" :name="'discount_food'+index" v-validate="'required'"  required autocomplete="off" v-model.number="discount_break.amount" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact d-flex"><span style="max-width: 160px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;">{{discount_break.category_id.name}}</span> Total Discount<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('discount_food'+index)" style="display: inline !important">{{discount_break.category_id.name}} Total Discount is Required</span>
                                        </div>
                                    </div>
                                    <div class="mt-8 d-flex">
                                        <span class="mr-2" v-if="index!=0" @click="create.discount_breakdown.splice(index, 1);removeDiscount(discount_break)">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/minus_red.svg" style="width:20px;cursor:pointer;" />
                                        </span>
                                        <span v-if="index+1==create.discount_breakdown.length" @click="create.discount_breakdown.push(JSON.parse(JSON.stringify(addcategory)))">
                                            <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/add-button-inside-black-circle.svg" style="width:20px;cursor:pointer;" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="section mt-5">
                            <div class="col-md-12 mb-5" style="background:#00448b !important;padding:10px !important;">
                                <span class="font-600" style="color:#fff !important;">Calculation Section</span>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" disabled v-on:keypress="isNumber($event)" name="price_total" v-validate="'required'"  required autocomplete="off" v-model.number="create.total_combo_price" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Total Price<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('price_total ')" style="display: inline !important">Total Price is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="text-secondary font-weight-bold mt-2">Discount<sup>★</sup></div>
                                    <el-radio-group v-model="create.discount.type" class="mt-3" @change="calculatePercentage()">
                                        <el-radio label="amount">Amount</el-radio>
                                        <el-radio label="percentage">Percentage</el-radio>
                                    </el-radio-group>
                                </div>
                                <div class="col-md-6" v-if="create.discount.type == 'amount'">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" @input="checkDiscountInput(create.discount.value)" v-on:keypress="isNumber($event)" name="value_name" v-validate="'required'"  required autocomplete="off" v-model.number="create.discount.value" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Amount<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('value_name ')" style="display: inline !important">Amount is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="create.discount.type == 'percentage'">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" @input="checkDiscountInput(create.discount.value)" v-on:keypress="isNumber($event)" name="value_name" v-validate="'required'"  required autocomplete="off" v-model.number="create.discount.value" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Percentage<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('value_name ')" style="display: inline !important">Percentage is Required</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="input-group" style="display: -webkit-inline-box;">
                                        <div class="form-group contactGroup" style="width:100%;">
                                            <input class="inputContact" disabled v-on:keypress="isNumber($event)" name="combo_price" v-validate="'required'"  required autocomplete="off" v-model.number="create.price_after_discount" type="text"/>
                                            <span class="highlightContact"></span>
                                            <span class="barContact"></span>
                                            <label class="labelContact">Combo Price<sup>★</sup></label>
                                            <span class="invalid-feedback-form text-danger fs-13" v-show="errors.has('combo_price ')" style="display: inline !important">Combo Price is Required</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5">
                    <a class="btn btn-danger btn-smm text-white" @click="cancel">CANCEL</a>
                    <button class="btn btn-success btn-smm text-uppercase text-white ml-5" @click="save()">SAVE</button>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import UploadPicture from './UploadPicture'
import { EventBus } from './eventBus/nav-bar-event'
import combo from './mixins/combo.js'
export default {
    props:['modal_name','source'],
    mixins: [combo],
    data(){
        return{
            savedCombo:false,
            editTrue:true,
            available_productType:true,
            total_product_price:0,
            count:0,
            search:'',
            limit:10,
            skip:0,
            sectionCategories:[],
            discountCategories:[],
            combonamelength:45,
            allProductTypes:[],
            temp_allProductTypes:[],
            allproducts:[],
            temp_products:[],
            allVariants:[],
            allCategories:[],
            isLoading:false,
            scrollableProduct:false,
            loadingProductType:false,
            scrollableProductType:false,
            isLoadingproduct:false,
            isVisible:false,
			imgDataUrl: 'https://cdn.digicollect.com/cdn/pos/images-new-theme/defaultUserPicture.svg', 
            addproduct:{
                product_id:'',
                variant_id:'',
                variant:false,
                quantity:0,
                total_price:0,
                discount_applied:true,
                discount:0,
                price_after_discount:0
            },
            addcategory:{
                category_id:'',
                amount:0
            },
            disc_amount:0,
            // CreateJson
            create:{
                status:true,
                name:'',
                image_url:'',
                category_id:'',
                sub_category_id:'',
                product_type_id:'',
                combo_items:[
                    {
                        product_id:'',
                        variant_id:'',
                        variant:false,
                        quantity:0,
                        total_price:0,
                        discount_applied:true,
                        discount:0,
                        price_after_discount:0
                    }
                ],
                total_combo_price:0,
                discount_applied:true,
                discount:{
                    type:'amount',
                    value:0
                },
                price_after_discount:0,
                discount_breakdown:[
                    {
                        category_id:'',
                        amount:0
                    }
                ]
            },
        }
    },
    components:{
        UploadPicture
    },
    methods:{
        clearAllData(){
            this.sectionCategories = []
            this.discountCategories = []
            this.available_productType = true
            this.create = {
                status:true,
                name:'',
                image_url:'',
                category_id:'',
                sub_category_id:'',
                product_type_id:'',
                combo_items:[
                    {
                        product_id:'',
                        variant_id:'',
                        variant:false,
                        quantity:0,
                        total_price:0,
                        discount_applied:true,
                        discount:0,
                        price_after_discount:0
                    }
                ],
                total_combo_price:0,
                discount_applied:true,
                discount:{
                    type:'amount',
                    value:0
                },
                price_after_discount:0,
                discount_breakdown:[
                    {
                        category_id:'',
                        amount:0
                    }
                ]
            }
        },
        uploadImage(imgData) {
			this.create.image_url = imgData
        },
        isNumber(evt, index) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 &&(charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        handleRemoveProdcut(prod){
            if(prod.product_id.hasOwnProperty('category')){
                if(prod.product_id != ''){
                    this.create.discount_breakdown.forEach((discount,index)=>{
                        if(discount.category_id._id == prod.product_id.category._id){
                            if(discount.amount >= prod.discount){
                                this.create.discount_breakdown[index].amount = this.create.discount_breakdown[index].amount - prod.discount
                            }
                        }
                    })
                    this.updateDiscountSection(prod.product_id.category)
                }
            }else{
                if(prod.hasOwnProperty('category') && prod.category != ''){
                    this.create.discount_breakdown.forEach((discount,index)=>{
                        if(discount.category_id._id == prod.category._id){
                            if(discount.amount >= prod.discount){
                                this.create.discount_breakdown[index].amount = this.create.discount_breakdown[index].amount - prod.discount
                            }
                        }
                    })
                    this.updateDiscountSection(prod.category)
                }
            }
            this.calculateTotalPrice()
        },
        discountCategory(discount,index){
            const newResult = []
            this.create.combo_items.forEach((co,index)=>{
                if(co.product_id.hasOwnProperty('category')){
                    if(co.product_id.category._id == discount.category_id._id){
                        newResult.push(co.total_price)
                    }
                }else{
                    if(co.category._id == discount.category_id._id){
                        newResult.push(co.total_price)
                    }
                }
            })
            let sum= 0;
            for (var i = 0; i < newResult.length; i++) {
                sum += newResult[i]
            }
            if(discount.amount > sum){
                this.create.discount_breakdown[index].amount = sum
                discount.amount = this.create.discount_breakdown[index].amount
            }
            const result = []
            this.create.combo_items.forEach((co,index)=>{
                if(co.product_id.hasOwnProperty('category')){
                    if(co.product_id.category._id == discount.category_id._id){
                        result.push(co.product_id.category._id)
                    }
                }else{
                    if(co.category._id == discount.category_id._id){
                        result.push(co.category._id)
                    }
                }
            })
            let length_result = discount.amount/result.length
            this.create.combo_items.forEach((co,index)=>{
                result.forEach((r,i)=>{
                    if(co.product_id.hasOwnProperty('category')){
                        if(co.product_id.category._id == r){
                            this.create.combo_items[index].discount = length_result 
                            this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount
                        }
                    }else{
                        if(co.category._id == r){
                            this.create.combo_items[index].discount = length_result 
                            this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount
                        }
                    }
                })
            })
            this.calculateTotalPrice()
        },
        discountPush(){
            this.discountCategories = []
            this.create.combo_items.forEach((product,index)=>{
                if(product.product_id != ''){
                    this.discountCategories.push(product.product_id.category || product.category)
                }
            })
            const result = [];
            const map = new Map();
            for (const item of this.discountCategories) {
                if(!map.has(item._id)){
                    map.set(item._id, true); 
                    result.push({
                        _id:item._id,
                        name:item.name
                    });
                }
            }
            this.discountCategories = result
            this.discountCategories.forEach((el,i)=>{
                this.create.discount_breakdown.forEach((dl,index)=>{
                    if(el._id == dl.category_id._id){
                        this.discountCategories.splice(i,1)
                    }
                })
            })
        },
        checkVariant(id,index){
            if(id != ''){
                this.create.combo_items[index].variant = id.variant
                this.create.combo_items[index].discount = 0
                this.create.combo_items[index].quantity = 0
                if(!id.variant){
                    if(id.quantity > 0){
                        this.create.combo_items[index].quantity = 1
                    }else{
                        this.create.combo_items[index].quantity = 0
                    }
                    this.create.combo_items[index].total_price = id.price * this.create.combo_items[index].quantity
                    this.create.combo_items[index].price_after_discount = id.price * this.create.combo_items[index].quantity
                }else{
                    this.create.combo_items[index].total_price = 0
                    this.create.combo_items[index].price_after_discount = 0
                }
                if(id.hasOwnProperty('category')){
                    this.updateDiscountSection(id.category)
                }else{
                    this.updateDiscountSection(id.product_id.category)
                }
            }
        },
        selectVariant(id,index,prod){
            if(id != ''){
                this.create.combo_items[index].discount = 0
                this.create.combo_items[index].quantity = 0
                if(id.variant_quantity > 0){
                    this.create.combo_items[index].quantity = 1
                }else{
                    this.create.combo_items[index].quantity = 0
                }
                this.create.combo_items[index].total_price = id.variant_price * this.create.combo_items[index].quantity
                this.create.combo_items[index].price_after_discount = id.variant_price * this.create.combo_items[index].quantity
                if(prod.hasOwnProperty('category')){
                    this.updateDiscountSection(prod.category)
                }else{
                    this.updateDiscountSection(prod.product_id.category)
                }
            }
        },
        checkQuantity(qty,prod,index,variant){
            if(prod != '' && !prod.variant){
                if(qty > prod.quantity){
                    this.create.combo_items[index].quantity = prod.quantity
                    this.create.combo_items[index].total_price = this.create.combo_items[index].quantity * prod.price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }else if(qty == ''){
                    this.create.combo_items[index].quantity = 0
                    this.create.combo_items[index].total_price = this.create.combo_items[index].quantity * prod.price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }else if(qty == 0){
                    this.create.combo_items[index].total_price = 0
                    this.create.combo_items[index].price_after_discount = 0
                }
                else{
                    this.create.combo_items[index].total_price = qty * prod.price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }
                this.checkDiscount(this.create.combo_items[index],index)
            }else if(prod != '' && prod.variant){
                if(qty > variant.variant_quantity){
                    this.create.combo_items[index].quantity = variant.variant_quantity
                    this.create.combo_items[index].total_price = this.create.combo_items[index].quantity * variant.variant_price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }else if(qty == ''){
                    this.create.combo_items[index].quantity = 0
                    this.create.combo_items[index].total_price = this.create.combo_items[index].quantity * variant.variant_price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }else{
                    this.create.combo_items[index].total_price = this.create.combo_items[index].quantity * variant.variant_price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price
                }
                this.checkDiscount(this.create.combo_items[index],index)
            }
        },
        checkDiscount(prod,index){
            if(prod.total_price != ''){
                if(prod.total_price < prod.discount){
                    this.create.combo_items[index].discount = this.create.combo_items[index].total_price
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount
                }else if(prod.discount == ''){
                    this.create.combo_items[index].discount = 0
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount
                }else{
                    this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount
                }
                if(prod.product_id.hasOwnProperty('category')){
                    let newId = prod.product_id.category
                    this.updateDiscountSection(newId)
                }else{
                    let newId = prod.category
                    this.updateDiscountSection(newId)
                }
            }
        },
        updateDiscountSection(newId){
            const result = []
            this.create.combo_items.forEach((p,i)=>{
                if(!p.product_id.hasOwnProperty('category')){
                    if(p.category._id == newId._id){
                        result.push(p.discount)
                    }
                }else{
                    if(p.product_id.category._id == newId._id){
                        result.push(p.discount)
                    }
                }
            })
            let sum= 0;
            for (var i = 0; i < result.length; i++) {
                sum += result[i]
            }
            this.create.discount_breakdown.forEach((category,index)=>{
                if(category.category_id != ''){
                    if(category.category_id._id == newId._id){
                        this.create.discount_breakdown[index].amount = sum
                    }else{
                        this.create.discount_breakdown.push({
                            category_id:newId,
                            amount:sum
                        })
                    }
                }else{
                    this.create.discount_breakdown[index].category_id = newId
                    this.create.discount_breakdown[index].amount = sum
                }
            })
            const resultdiscount = [];
            const mapnew = new Map();
            for (const item of this.create.discount_breakdown) {
                if(!mapnew.has(item.category_id._id)){
                    mapnew.set(item.category_id._id, true); 
                    resultdiscount.push({
                        category_id:item.category_id,
                        amount:item.amount
                    });
                }
            }
            this.create.discount_breakdown = resultdiscount
            const resultproduct = [];
            this.create.combo_items.forEach((item,index)=>{
                if(item.product_id.hasOwnProperty('category')){
                    resultproduct.push(item.product_id.category._id)
                }else{
                    resultproduct.push(item.category._id)
                }
            })
            const resultcategory = [...new Set(this.create.discount_breakdown.map(x =>x.category_id._id))]
            const array3 = resultcategory.filter(function(item) {
                return !resultproduct.includes(item.split('.')[0]);
            })
            this.create.discount_breakdown.forEach((discount,dindex)=>{
                array3.forEach((ar,i)=>{
                    if(discount.category_id._id == ar){
                        this.create.discount_breakdown.splice(dindex,1)
                    }
                })
            })
            this.calculateTotalPrice()
        },
        checkDiscountInput(value){
            if(this.create.discount.type == 'amount'){
                const newArray = []
                this.create.combo_items.forEach((co,index)=>{
                    newArray.push(co.total_price)
                })
                let sum= 0;
                for (var i = 0; i < newArray.length; i++) {
                    sum += newArray[i]
                }
                this.total_product_price = sum
                this.create.combo_items.forEach((combo,index)=>{
                    if(combo.total_price != ''){
                        if(value > combo.total_price){
                            value = this.create.combo_items[index].total_price
                            this.create.discount.value = value
                            this.create.combo_items[index].discount = ((combo.total_price/this.total_product_price)*value)
                            this.create.combo_items[index].price_after_discount = parseFloat(this.create.combo_items[index].total_price - this.create.combo_items[index].discount)
                            this.create.price_after_discount = (this.create.total_combo_price - this.create.discount.value)
                            this.disc_amount = this.create.discount.value
                        }else{
                            this.create.combo_items[index].discount = ((combo.total_price/this.total_product_price)*value)
                            this.create.combo_items[index].price_after_discount = parseFloat(this.create.combo_items[index].total_price - this.create.combo_items[index].discount)  
                            this.create.price_after_discount = (this.create.total_combo_price - this.create.discount.value)
                            this.disc_amount = this.create.discount.value
                        }
                        this.calculateTotalDiscount()
                    }
                })
            }else if(this.create.discount.type == 'percentage'){
                this.create.combo_items.forEach((combo,index)=>{
                    if(value > 100){
                        value = 100
                        this.create.discount.value = 100
                        this.create.combo_items[index].discount = ((combo.total_price/100)*value)
                        this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount  
                        this.disc_amount = (this.create.total_combo_price/100)*value
                        this.create.price_after_discount = (this.create.total_combo_price - this.disc_amount)
                    }else{
                        this.create.combo_items[index].discount = ((combo.total_price/100)*value)
                        this.create.combo_items[index].price_after_discount = this.create.combo_items[index].total_price - this.create.combo_items[index].discount  
                        this.disc_amount = (this.create.total_combo_price/100)*value 
                        this.create.price_after_discount = (this.create.total_combo_price - this.disc_amount)
                    } 
                    this.calculateTotalDiscount()  
                })
            }
        },
        calculateTotalDiscount(){
            let disc_length = this.create.discount_breakdown.length
            let new_value = this.disc_amount/disc_length
            this.create.discount_breakdown.forEach((disc,index)=>{
                this.create.discount_breakdown[index].amount = new_value
            })
        },
        calculateTotalPrice(){
            const newArray = []
            this.create.combo_items.forEach((co,index)=>{
                newArray.push(co.total_price)
            })
            let sum= 0;
            for (var i = 0; i < newArray.length; i++) {
                sum += newArray[i]
            }
            this.create.total_combo_price = sum
            if(this.create.discount.type == 'amount'){
                const newArray1 = []
                this.create.combo_items.forEach((co,index)=>{
                    newArray1.push(co.discount)
                })
                let sum1= 0;
                for (var i = 0; i < newArray1.length; i++) {
                    sum1 += newArray1[i]
                }
                this.create.discount.value = sum1 
                this.create.price_after_discount = this.create.total_combo_price - this.create.discount.value
            }else{
                const newArray2 = []
                this.create.discount_breakdown.forEach((co,index)=>{
                    newArray2.push(co.amount)
                })
                let sum2= 0;
                for (var i = 0; i < newArray2.length; i++) {
                    sum2 += newArray2[i]
                }
                this.create.discount.value = sum2 
                this.create.discount.value = ((this.create.discount.value/this.create.total_combo_price)*100)
            }
        },
        calculatePercentage(){
            this.create.combo_items.forEach((product,index)=>{
                product.discount = 0
                product.price_after_discount = product.total_price - product.discount
            })
            this.create.discount_breakdown.forEach((cat,index)=>{
                cat.amount = 0
            })
            this.create.discount.value = 0
            this.create.price_after_discount = (this.create.total_combo_price - this.create.discount.value)
        },
        async getVariant(id){
            try {
                if(id == ''){

                }else{
                    let params ={
                        skip:this.skip,
                        limit:this.limit,
                        search:this.search,
                        product_id:id.product_id
                    }
                    let res = await this.$http.post('combos/get_variants_for_dropdown',params) 
                    this.allVariants = res.data.products
                }
            } catch (reason) {
                
            }
        },
        // Product Type
        async loadProductType(){
            let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
                searchdata: this.search,
                skip: 0,
                limit: 10,
                sub_category_id:this.create.sub_category_id._id
            }) 
            this.allProductTypes = response.data.product_types
            this.count = this.temp_allProductTypes.length
            if(response.data.product_types.length == 0){
                this.available_productType = false
                this.$validator.reset();
				this.errors.clear(); 
            }else{
                this.available_productType = true
            }
            this.loadingProductType = false
            this.scrollableProductType = false
        },
        async loadProductTypeSearch(search){
            this.loadingProductType = true
            let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
                searchdata: search,
                skip: 0,
                limit: 10,
                sub_category_id:this.create.sub_category_id._id
            })
            this.allProductTypes = response.data.product_types
            this.count = this.temp_allProductTypes.length
            if(response.data.product_types.length == 0){
                this.available_productType = false
                this.$validator.reset();
				this.errors.clear(); 
            }else{
                this.available_productType = true
            }
            this.loadingProductType = false
            this.scrollableProductType = false
        },
        async reachedEndOfListProductType(reached) {
            this.isVisible = reached
            if (reached) {
                if (this.scrollableProductType == false) {
                    this.loadingProductType = true
                    let response = await this.$http.post('/categories/get_product_types_for_sub_categories', {
                        searchdata: this.search,
                        skip: this.count,
                        limit: 10,
                        sub_category_id:this.create.sub_category_id._id
                    })
                    this.temp_allProductTypes = response.data.product_types.length
                    if(response.data.product_types.length == 0){
                        this.available_productType = false
                        this.$validator.reset();
						this.errors.clear(); 
                    }else{
                        this.available_productType = true
                    }
                    if (response.data.skip == this.count) {
                        if (this.temp_allProductTypes > 0) {
                            let tempFiles = response.data.product_types
                            tempFiles.forEach(el => {
                                this.allProductTypes.push(el)
                            })
                            this.count += response.data.product_types.length
                            this.loadingProductType = false
                        } else {
                            this.scrollableProductType = true
                            this.loadingProductType = false
                        }
                    }
                    this.loadingProductType = false
                }
            }
        }, 
        // Products
        async loadProductSearch(search){
            this.isLoadingproduct = true
            let response = await this.$http.post('/combos/get_products_for_dropdown', {
                search: search,
                skip: 0,
                limit: 10
            })
            this.allproducts = response.data.products
            this.count = this.temp_products.length
            this.isLoadingproduct = false
            this.scrollableProduct = false
        },
        async reachedEndOfListProduct(reached) {
            this.isVisible = reached
            if(this.editTrue){
                this.allproducts = []
                this.editTrue = false
            }
            if (reached) {
                if (this.scrollableProduct == false) {
                this.isLoadingproduct = true
                let response = await this.$http.post('/combos/get_products_for_dropdown', {
                    search: this.search,
                    skip: this.count,
                    limit: 10
                })
                this.temp_products = response.data.products.length
                if (response.data.skip == this.count) {
                    if (this.temp_products > 0) {
                        let tempFiles = response.data.products
                        tempFiles.forEach(el => {
                            this.allproducts.push(el)
                        })
                        this.count += response.data.products.length
                        this.isLoadingproduct = false
                    } else {
                        this.scrollableProduct = true
                        this.isLoadingproduct = false
                    }
                }
                    this.isLoadingproduct = false
                }
            }
        },
        cancel(){
            this.clearAllData()
            if(this.source == 'viewCombo'){
                if(this.savedCombo){
                    this.$emit('cancelviewEditsaved')
                }else{
                    this.$emit('cancelviewEdit')
                }
            }
            this.$modal.hide(this.modal_name)
            this.savedCombo = false
        },
        save(){
            this.$validator.validateAll().then(result => {
        		if (result) {
                    let params = this.create
					this.confirmSave(params)
				}
			})
        },
        confirmSave(params){
            const resultdiscount = [];
            const mapnew = new Map();
            for (const item of params.discount_breakdown) {
                if(!mapnew.has(item.category_id._id)){
                    mapnew.set(item.category_id._id, true); 
                    resultdiscount.push({
                        category_id:item.category_id._id,
                        amount:item.amount
                    });
                }
            }
            params.discount_breakdown = resultdiscount
            const resultproduct = [];
            const map = new Map();
            for (const item of params.combo_items) {
                if(!map.has(item.product_id.product_id)){
                    map.set(item.product_id.product_id && item.variant_id.variant_id, true);  
                    resultproduct.push({
                        product_id: item.product_id.product_id,
                        variant_id:item.variant_id.variant_id || '',
                        variant:item.product_id.variant,
                        discount:item.discount,
                        discount_applied:item.discount_applied,
                        price_after_discount:item.price_after_discount,
                        quantity:item.quantity,
                        total_price:item.total_price
                    });
                }
            }
            params.combo_items = resultproduct
            params.sub_category_id = params.sub_category_id._id
            params.product_type_id = params.product_type_id._id || ''
            params.category_id = params.category_id._id
            delete params.outlet_currency
            this.editCombo(params)
        },
        async editCombo(params){
            try {
                let res = await this.editComboApi(params)
                this.$swal({
                    title: res.response,
                    text: '',
                    type: 'success'
                })
                this.savedCombo = true
                this.cancel()
                this.$emit('editCombo')
            } catch (reason) {
                this.$swal({
                    title: reason,
                    text: '',
                    type: 'warning'
                })
                this.handleErrorResponse(params)
            }
        },
        updateSubcategory(){
            this.create.sub_category_id = ''
            this.create.sub_category_id = this.create.category_id.sub_category
            this.allProductTypes = []
            this.create.product_type_id = ''
            this.temp_allProductTypes = []
            this.scrollableProductType = false
            this.count = 0
        },
        removeDiscount(d){
            if(d.category_id != ''){
                this.create.combo_items.forEach((item,index)=>{
                    if(item.hasOwnProperty('category')){
                        if(item.category._id == d.category_id._id){
                            this.create.combo_items.splice(index,1)
                        }
                    }else{
                        if(item.product_id.category._id == d.category_id._id){
                            this.create.combo_items.splice(index,1)
                        } 
                    }
                })
                this.calculateTotalPrice()
            }
        },
        handleErrorResponse(params){
            this.sectionCategories.forEach((cat,index)=>{
                if(cat._id == params.category_id){
                    this.create.category_id = cat
                    this.create.sub_category_id = cat.sub_category
                }
            })
            this.allProductTypes.forEach((p,i)=>{
                if(p._id == params.product_type_id){
                    this.create.product_type_id = p
                }
            })
            this.discountCategories.forEach((cat,index)=>{
                params.discount_breakdown.forEach((p,i)=>{
                    if(cat._id == p.category_id){
                        this.create.discount_breakdown[i].category_id = cat
                    }
                })
            })
            this.allproducts.forEach((product,index)=>{
                params.combo_items.forEach((co,i)=>{
                    if(product.product_id == co.product_id){
                        this.create.combo_items[i].product_id = product
                    }
                })
            })
        }
    },
    created() {
		const dict = {
			custom: {
				combo_name: {
					required: () => "Combo Name is Required"
                },
            }
        }
        this.$validator.localize("en", dict);
    },
    mounted(){
        EventBus.$on('getEditData',params=>{
            this.$http.post('/combos/get_combo_by_id',params).then(res=>{
                if(res.data.status_id == 1){
                    this.create = res.data.combo
                    this.create.discount_breakdown.forEach((cat,index)=>{
                        this.discountCategories.push(cat.category_id)
                    })
                    this.create.combo_items.forEach((p,i)=>{
                        this.allproducts.push(p.product_id)
                    })
                }
            }).then(res=>{
                this.$http.get('/combos/get_combo_creation_details').then(res=>{
                    if(res.data.status_id == 1){
                        this.sectionCategories = res.data.combo_creation_details
                    }else{

                    }
                })
            }).then(res=>{
                if(this.create.product_type_id == ''){
                    this.available_productType = false
                }
            }).then(res =>{
                this.loadProductType()
            })
        })
    }
}
</script>
<style scoped>
.section {
  padding: 20px !important;
  background: #fff !important;
  border-radius: 12px !important;
}
.valueInput{
    border-bottom: solid 1px #a9aaad !important;
    margin-top: 3px;
}
.topmargin{
    margin-top: -8px !important;
}
.comboModal .labelContact {
    top: 20px !important;
}
.labelContactnew .labelContact span{
    padding-top: 0px !important;
}
.labelContactnew .labelContact {
    top: 10px !important;
}
</style>